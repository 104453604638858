<h3 mat-dialog-title [innerHTML]="('CONFIRMATION.' + data.text) | translate: data.param">
  <!-- <span [innerHTML]="('CONFIRMATION.' + data.text) | translate: data.param"></span> -->
</h3>

<mat-dialog-actions align="end">
  <button aria-label="no"
          class="mb-3"
          *ngIf="!data.uniqueAction"
          mat-raised-button
          color="accent"
          type="button"
          (click)="dialogRef.close(false)">
    {{ 'ACTION.SHARED.NO' | translate }}
  </button>
  <button aria-label="yes"
          class="mb-3"
          mat-raised-button
          cdkFocusInitial
          color="primary"
          type="button"
          (click)="onClickYes()">
    {{ data.uniqueAction ? 'OK' : ('ACTION.SHARED.YES' | translate) }}
  </button>
</mat-dialog-actions>